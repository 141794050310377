import React, { useState, useEffect } from 'react';
import BasicPageStructure from '../../BasicPageStructure';
import styles from './NotificacoesComponent.module.css';
import NotificacaoViewComponent from './NotificacaoViewComponent';
import {
    IoMdCheckmarkCircle,
    IoMdWarning,
    IoMdHelpCircle,
    IoMdAlert,
    IoMdFlash
} from 'react-icons/io';
import { useGetLoggedUser } from '../../../actions/UserActions';
import { PropagateLoader } from 'react-spinners';
import useNotificacoes from '../../../hooks/Notificacoes/useNotificacoes';

const NotificacoesComponent = () => {
    const { user, loading: loadingUser } = useGetLoggedUser();
    const [notificacaoSelecionada, setNotificacaoSelecionada] = useState(null);

    const {
        notificacoes: todasNotificacoes,
        loading: loadingNotificacoes,
        marcarComoVisualizado
    } = useNotificacoes({ status: "Ativo" });

    const [notificacoesFiltradas, setNotificacoesFiltradas] = useState([]);

    // Aplica o filtro com base nas turmas do responsável
    useEffect(() => {
        if (!user || !todasNotificacoes) return;

        let filtradas = [];

        if (user.responsavelInfo?.length > 0) {
            const seriesPermitidas = user.responsavelInfo.map(info =>
                String(info.serie).trim().toLowerCase()
            );
            const turnosPermitidos = user.responsavelInfo.map(info =>
                String(info.turno).trim().toLowerCase()
            );

            filtradas = todasNotificacoes.filter(notificacao =>
                notificacao.acessoTurmas?.some(turma => {
                    if (!turma.serie || !turma.turno) return false;
                    const serie = String(turma.serie).trim().toLowerCase();
                    const turno = String(turma.turno).trim().toLowerCase();
                    return seriesPermitidas.includes(serie) && turnosPermitidos.includes(turno);
                })
            );
        } else {
            filtradas = todasNotificacoes;
        }

        setNotificacoesFiltradas(filtradas);

        filtradas.forEach(async (notificacao) => {
            if (!notificacao.visualizadoPor?.includes(user?._id)) {
                try {
                    await marcarComoVisualizado(notificacao._id, user._id);
                } catch (err) {
                    console.warn(`⚠️ Erro ao marcar notificação ${notificacao._id} como visualizada`);
                }
            }
        });
        
        // 🔁 Ordena da mais recente para a mais antiga
        const ordenadas = [...filtradas].sort((a, b) => new Date(b.criadoEm) - new Date(a.criadoEm));
        setNotificacoesFiltradas(ordenadas);

    }, [user, todasNotificacoes]);

    const renderPriorityIcon = (prioridade) => {
        switch (prioridade) {
            case 1: return <IoMdCheckmarkCircle className={styles.prioridadeBaixa} title="Baixa Prioridade" />;
            case 2: return <IoMdWarning className={styles.prioridadeMediaBaixa} title="Prioridade Média Baixa" />;
            case 3: return <IoMdHelpCircle className={styles.prioridadeMedia} title="Prioridade Média" />;
            case 4: return <IoMdAlert className={styles.prioridadeAlta} title="Alta Prioridade" />;
            case 5: return <IoMdFlash className={styles.prioridadeUrgente} title="Urgente!" />;
            default: return null;
        }
    };

    const isLoading = loadingUser || loadingNotificacoes;

    return (
        <BasicPageStructure
            content={
                <div className={styles.notificacoesContainer}>
                    {isLoading ? (
                        <div className={styles.sliderContainer}>
                            <PropagateLoader color="#EC3237" size={15} />
                        </div>
                    ) : notificacoesFiltradas.length > 0 ? (
                        <ul className={styles.listaNotificacoes}>
                            {notificacoesFiltradas.map((notificacao) => {
                                const foiVisualizado = notificacao.visualizadoPor?.includes(user?._id);
                                return (
                                    <li
                                        key={notificacao._id}
                                        className={`${styles.notificacaoItem} ${!foiVisualizado ? styles.naoVisualizado : ''}`}
                                        onClick={() => setNotificacaoSelecionada(notificacao)}
                                    >
                                        <div className={styles.cabecalho}>
                                            <span className={`${styles.tipo} ${styles[notificacao.tipo?.toLowerCase()]}`}>
                                                {notificacao.tipo}
                                            </span>
                                            <strong>{notificacao.titulo}</strong>
                                        </div>
                                        <p>{notificacao.descricao}</p>
                                        <div className={styles.rodape}>
                                            <span className={styles.data}>
                                                {new Date(notificacao.criadoEm).toLocaleDateString('pt-BR', {
                                                    weekday: 'long',
                                                    day: '2-digit',
                                                    month: 'long',
                                                    year: 'numeric'
                                                })}
                                            </span>
                                            <span className={styles.prioridadeIcon}>
                                                {renderPriorityIcon(notificacao.prioridade)}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p>❌ Nenhuma notificação disponível.</p>
                    )}

                    {notificacaoSelecionada && (
                        <NotificacaoViewComponent
                            notificacao={notificacaoSelecionada}
                            onClose={() => setNotificacaoSelecionada(null)}
                        />
                    )}
                </div>
            }
            link="/"
            namePage="Notificações"
        />
    );
};

export default NotificacoesComponent;
